import { render, staticRenderFns } from "./FixedToTopButton.vue?vue&type=template&id=40b9d496&scoped=true&"
import script from "./FixedToTopButton.vue?vue&type=script&lang=ts&"
export * from "./FixedToTopButton.vue?vue&type=script&lang=ts&"
import style0 from "FixedToTopButton.sass?vue&type=style&index=0&id=40b9d496&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40b9d496",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('40b9d496')) {
      api.createRecord('40b9d496', component.options)
    } else {
      api.reload('40b9d496', component.options)
    }
    module.hot.accept("./FixedToTopButton.vue?vue&type=template&id=40b9d496&scoped=true&", function () {
      api.rerender('40b9d496', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/Component/Layout/FixedButtons/ToTop/FixedToTopButton.vue"
export default component.exports