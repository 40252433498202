
























import FindJobModule from '../../../MainMenu/Module/FindJobModule/FindJobModule.vue';

export default {
    name: 'HeaderFindJobModule',
    components: {FindJobModule}
};
