



























import {HelferleinEventList} from '@labor-digital/helferlein/lib/Events/HelferleinEventList';
import {getScrollPos} from '@labor-digital/helferlein/lib/Ui/getScrollPos';
import {scrollToTopOf} from '@labor-digital/helferlein/lib/Ui/scrollToTopOf';
import {PageContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/PageContext';
import {AppStoreKeys} from '../../../../AppStoreKeys';
import ComponentProxyAwareMixin from '../../../../Mixin/ComponentProxyAwareMixin';
import Btn from '../../../Misc/Btn/Btn.vue';

export default {
    name: 'FixedToTopButton',
    components: {Btn},
    mixins: [ComponentProxyAwareMixin],
    props: {
        context: {
            required: true
        } as PageContext
    },
    data()
    {
        return {
            scrollPos: 0
        };
    },
    computed: {
        /**
         * Returns true if a sidebar is open and we should hide the buttons...
         */
        isSidebarOpen(): boolean
        {
            return this.context.store.get(AppStoreKeys.SIDEBAR_DIRECTION, null) !== null;
        },
        isVisible()
        {
            // Check if the sidebar is open
            if (this.isSidebarOpen) {
                return false;
            }
            return this.scrollPos > 300;
        },
        /**
         * Returns the list of css classes applied to the component
         */
        classes()
        {
            return {
                'fixedToTopButton--shown': this.isVisible
            };
        }
    },
    methods: {
        onClick()
        {
            scrollToTopOf();
        }
    },
    mounted(): void
    {
        this.proxy.bind(
            this.context.eventEmitter,
            HelferleinEventList.EVENT_SCROLL_THROTTLED,
            () => {
                this.scrollPos = getScrollPos();
            });
    }
};
