var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fixedToTopButton", class: _vm.classes },
    [
      _c("btn", {
        staticClass: "fixedToTopButton__button",
        attrs: { "icon-position": "withoutLabel", icon: "arrowUp" },
        nativeOn: {
          click: function ($event) {
            return _vm.onClick.apply(null, arguments)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }